<template>
    <div>
        <a class="banner" href="https://www.daangn.com/kr/business-profiles/%EC%8A%A4%EC%9C%84%EB%B0%8D%EB%AA%B0-88f133168d8240cf95b5e44045289b9a/" target="_blank" style="display: block">
            <img src="@/assets/photo/bn00.jpg" alt="스위밍몰" class="ad-image" />
        </a>
        <div class="banner">
            <img src="@/assets/photo/banner1.jpg" alt="원이" class="ad-image" />
        </div>
        <div class="banner">
            <img src="@/assets/photo/banner2.jpg" alt="결혼" class="ad-image" />
        </div>
        <div class="banner">
            <img src="@/assets/photo/banner3.jpg" alt="덕산" class="ad-image" />
        </div>
        <div class="banner">
            <img src="@/assets/photo/banner4.jpg" alt="부찌" class="ad-image" />
        </div>
        <div class="banner">
            <img src="@/assets/photo/banner5.jpg" alt="순대" class="ad-image" />
        </div>
        <div class="banner">
            <img src="@/assets/photo/banner6.jpg" alt="부동산" class="ad-image" />
        </div>
        <a class="banner" href="https://www.instagram.com/m.i.k.a.makeup/" target="_blank" style="display: block">
            <img src="@/assets/photo/banner7.jpg" alt="메이크업" class="ad-image" />
        </a>
        <div class="banner">
            <img src="@/assets/photo/banner8.jpg" alt="레아" class="ad-image" />
        </div>
    </div>
</template>

<script>

export default {
    name: "bannerComponent",
    components: {
    },
    data() {
        return {
        };
    },
};
</script>