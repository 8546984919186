var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mb-3",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/event')}}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/event_main.jpg"),"alt":"썸네일"}})]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-6 mb-3",on:{"click":function($event){return _vm.$router.push('/start')}}},[_vm._m(0)]),_c('div',{staticClass:"col-6 mb-3",on:{"click":function($event){return _vm.$router.push('/goods_swimwear')}}},[_vm._m(1)]),_c('div',{staticClass:"col-6 mb-3",on:{"click":function($event){return _vm.$router.push('/goods_cap')}}},[_vm._m(2)]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls01.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2"},[_vm._v("START CLASS (종료)"),_c('br'),_vm._v("10. 27 SUN 08시 - 10시")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/goods_swimwear.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2"},[_vm._v("보노보노 수영복 오픈"),_c('br'),_c('span',{staticClass:"highlighted-text"},[_vm._v("(11월 14일 오후7시 신청마감)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/goods_cap.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2"},[_vm._v("보노보노 수모 오픈"),_c('br'),_c('span',{staticClass:"highlighted-text"},[_vm._v("(11월 11일 오후4시 신청마감)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls02.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls03.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls04.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls05.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls06.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls07.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls08.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls_etc_01.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls_etc_02.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls_etc_03.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls_etc_04.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
}]

export { render, staticRenderFns }