<template>
    <div>
        <div class="mb-3" @click="$router.push('/event')" style="cursor: pointer;">
            <!--<img src="@/assets/image/cheering_changwon.jpg" class="img-fluid" alt="bear" />-->
            <!--<img src="@/assets/image/cheering.png" class="img-fluid" alt="cheering" />-->
            <img src="@/assets/image/event_main.jpg" class="img-fluid" alt="썸네일" />
            <!--<img src="@/assets/image/main.gif" class="img-fluid" alt="메인" />-->
        </div>
        <div class="row justify-content-center">
            <div class="col-6 mb-3" @click="$router.push('/start')">
                <div class="thumbnail-box">
                    <img src="@/assets/image/cls01.jpg" class="img-fluid" alt="썸네일" />
                    <p class="text-center mt-2 ">START CLASS (종료)<br>10. 27 SUN 08시 - 10시</p>
                </div>
            </div>
            <div class="col-6 mb-3" @click="$router.push('/goods_swimwear')">
                <div class="thumbnail-box">
                    <img src="@/assets/image/goods_swimwear.jpg" class="img-fluid" alt="썸네일" />
                    <p class="text-center mt-2 ">보노보노 수영복 오픈<br>
                        <span class="highlighted-text">(11월 14일 오후7시 신청마감)</span>
                    </p>
                </div>
            </div>
            <div class="col-6 mb-3" @click="$router.push('/goods_cap')">
                <div class="thumbnail-box">
                    <img src="@/assets/image/goods_cap.jpg" class="img-fluid" alt="썸네일" />
                    <p class="text-center mt-2 ">보노보노 수모 오픈<br>
                        <span class="highlighted-text">(11월 11일 오후4시 신청마감)</span>
                        </p>
                </div>
            </div>
            <!--<div class="col-6 mb-3" >-->
            <!--    <div class="thumbnail-box">-->
            <!--        <img src="@/assets/image/goods_socks.jpg" class="img-fluid" alt="썸네일" />-->
            <!--        <p class="text-center mt-2 ">보노보노 양마루(준비중)</p>-->
            <!--    </div>-->
            <!--</div>-->
            <!--<div class="col-6 mb-3">-->
            <!--    <div class="thumbnail-box" @click="$router.push('/goods')">-->
            <!--        <img src="@/assets/image/goods.jpg" class="img-fluid" alt="굿즈" />-->
            <!--        <p class="text-center mt-2 text-secondary">보노보노 굿즈<br>(수모,키링,티셔츠,양말)</p>-->
            <!--    </div>-->
            <!--</div>-->
            <div class="col-6 mb-3">
                <div class="thumbnail-box">
                    <img src="@/assets/image/cls02.jpg" class="img-fluid" alt="썸네일" />
                    <p class="text-center mt-2 text-secondary">(준비중)</p>
                </div>
            </div>
            <div class="col-6 mb-3">
                <div class="thumbnail-box">
                    <img src="@/assets/image/cls03.jpg" class="img-fluid" alt="썸네일" />
                    <p class="text-center mt-2 text-secondary">(준비중)</p>
                </div>
            </div>
            <div class="col-6 mb-3">
                <div class="thumbnail-box">
                    <img src="@/assets/image/cls04.jpg" class="img-fluid" alt="썸네일" />
                    <p class="text-center mt-2 text-secondary">(준비중)</p>
                </div>
            </div>
            <div class="col-6 mb-3">
                <div class="thumbnail-box">
                    <img src="@/assets/image/cls05.jpg" class="img-fluid" alt="썸네일" />
                    <p class="text-center mt-2 text-secondary">(준비중)</p>
                </div>
            </div>
            <div class="col-6 mb-3">
                <div class="thumbnail-box">
                    <img src="@/assets/image/cls06.jpg" class="img-fluid" alt="썸네일" />
                    <p class="text-center mt-2 text-secondary">(준비중)</p>
                </div>
            </div>
            <div class="col-6 mb-3">
                <div class="thumbnail-box">
                    <img src="@/assets/image/cls07.jpg" class="img-fluid" alt="썸네일" />
                    <p class="text-center mt-2 text-secondary">(준비중)</p>
                </div>
            </div>
            <div class="col-6 mb-3">
                <div class="thumbnail-box">
                    <img src="@/assets/image/cls08.jpg" class="img-fluid" alt="썸네일" />
                    <p class="text-center mt-2 text-secondary">(준비중)</p>
                </div>
            </div>
            <div class="col-6 mb-3">
                <div class="thumbnail-box">
                    <img src="@/assets/image/cls_etc_01.jpg" class="img-fluid" alt="썸네일" />
                    <p class="text-center mt-2 text-secondary">(준비중)</p>
                </div>
            </div>
            <div class="col-6 mb-3">
                <div class="thumbnail-box">
                    <img src="@/assets/image/cls_etc_02.jpg" class="img-fluid" alt="썸네일" />
                    <p class="text-center mt-2 text-secondary">(준비중)</p>
                </div>
            </div>
            <div class="col-6 mb-3">
                <div class="thumbnail-box">
                    <img src="@/assets/image/cls_etc_03.jpg" class="img-fluid" alt="썸네일" />
                    <p class="text-center mt-2 text-secondary">(준비중)</p>
                </div>
            </div>
            <div class="col-6 mb-3">
                <div class="thumbnail-box">
                    <img src="@/assets/image/cls_etc_04.jpg" class="img-fluid" alt="썸네일" />
                    <p class="text-center mt-2 text-secondary">(준비중)</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
        };
    },
    mounted(){
    },
    computed: {
    },
    methods:{
    }
};
</script>

<style>
/* 스타일 추가 (예: form 스타일링) */
</style>
